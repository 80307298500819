export const CONSTANTS_ERRORS = {
  HTTP_400: 400,
  HTTP_403: 403,
  HTTP_404: 404,
  HTTP_409: 409,
  HTTP_410: 410,
  HTTP_412: 412,
  HTTP_429: 429,
  HTTP_500: 500,
  ERR_10001: 10001,
  ERR_10002: 10002,
  ERR_8889: 8889,
  ERR_5400: 5400,
  ERR_6400: 6400,
  ERR_2403: 2403
};
